import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import Modal from "react-modal";
import DataTable from "react-data-table-component";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import axios from "../../Utils/Axios";
import ReactPaginate from "react-paginate";
import { useAlert } from "react-alert";
import { DateRangePicker } from "react-dates";
import "./Orders.css";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";

function PendingOrders(props) {
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState();
  const [per_page, setPer_page] = useState(10);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [codType, setCodType] = useState(0);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [focusedInput, setFocusedInput] = useState(null);
  const [otp, setOtp] = useState("");
  const alert = useAlert();
  const customStyles = {
    content: {
      background: "linear-gradient(180deg,#00475d,#00a6d9)",
      top: "30%",
      left: "40%",
      right: "auto",
      bottom: "auto",
      marginRight: "0%",
      transform: "fade in",
    },
  };

  function closeModal() {
    setIsOpen(false);
    setOtp("");
  }
  function closeStatusModal() {
    setIsStatusOpen(false);
    setOrderId(0);
  }

  useEffect(() => {
    orders_list();
  }, [page, per_page, search, endDate, startDate]);

  const sentOtp = (order_id) => {
    setOrderId(order_id);
    setLoading(true);
    let formdata = new FormData();
    formdata.append("order_id", order_id);
    axios(`Orders/sentOtp`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === false) {
          setIsOpen(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const verifyOtp = () => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("order_id", orderId);
    formdata.append("otp", otp);
    axios(`Orders/verifyOtp`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === false) {
          orders_list();
          setIsOpen(false);
          setOtp("");
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const updateOrderStatus = (order_id, status, cashOrCard) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("order_id", order_id);
    formdata.append("status", status);
    formdata.append("cod_type", cashOrCard);
    axios(`Orders/updateStoreOrderStatus`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          closeStatusModal();
          orders_list();
          setLoading(false);
        } else {
          closeStatusModal();
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const orderDelivery = (order_id, payment_status) => {
    if (payment_status == 1) {
      updateOrderStatus(order_id, "delivered", 1);
    } else {
      setOrderId(order_id);
      setIsStatusOpen(true);
    }
  };

  const itemReached = (id) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("order_id", id);
    axios(`Orders/make_item_reached`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === false) {
          orders_list();
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const orders_list = () => {
    let start = "";
    startDate && (start = moment(startDate).format("YYYY-MM-DD").toString());
    let end = "";
    endDate && (end = moment(endDate).format("YYYY-MM-DD").toString());
    setLoading(true);
    axios(
      `Orders/store_order_list?order_status=pending&page=${page}&per_page=${per_page}&search=${search}&start_date=${start}&end_date=${end}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (!res.data.status) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setOrders([]);
            props.setPendingcount(res.data.total_count);
            setLoading(false);
          } else {
            setOrders(res.data.data);
            setTotal(res.data.total);
            props.setPendingcount(res.data.total_count);
            //setPer_page(10);
            setLoading(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        // onAfterOpen={afterOpenModal}
        shouldCloseOnOverlayClick={false}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {/* <h2>Verify OTP</h2>
        <OtpInput
          inputStyle="inputStyle"
          value={otp}
          onChange={(otp) => setOtp(otp)}
          numInputs={4}
          separator={<span></span>}
        />
        <input type="button" onClick={() => verifyOtp()} value="Confirm" />
        <input type="button" onClick={closeModal} value="Cancell" /> */}

        <div className="view">
          <div className="card">
            <h2>Enter verification code</h2>
            <div className="margin-top--small">
              <OtpInput
                inputStyle="inputStyle"
                value={otp}
                onChange={(otp) => setOtp(otp)}
                numInputs={4}
                separator={<span></span>}
              />
            </div>
            <div className="btn-row" style={{ justifyContent: "center" }}>
              <button
                className="btn btn-sm btn-danger btn-modal"
                type="button"
                onClick={closeModal}
              >
                Cancell
              </button>
              <button
                className="btn btn-sm btn-success btn-modal"
                onClick={() => verifyOtp()}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isStatusOpen}
        // onAfterOpen={afterOpenModal}
        shouldCloseOnOverlayClick={false}
        onRequestClose={closeStatusModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="view">
          <div className="card">
            <h4>Select Payment Type</h4>
            <div className="row" style={{ justifyContent: "center" }}>
              <select onChange={(e) => setCodType(e.target.value)}>
                <option value="0" selected>
                  {" "}
                  Cash{" "}
                </option>
                <option value="1"> Card </option>
              </select>
            </div>

            <div className="btn-row" style={{ justifyContent: "center" }}>
              <button
                className="btn btn-sm btn-danger btn-modal"
                type="button"
                onClick={closeStatusModal}
              >
                Cancell
              </button>
              <button
                className="btn btn-sm btn-success btn-modal"
                onClick={() => updateOrderStatus(orderId, "delivered", codType)}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800">Pending Orders</h1>

        <div class="popupCard shadow mb-4">
          <div class="card-body">
            <div className="row">
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search...."
                />
              </div>
              <div className="col-md-4">
                <DateRangePicker
                  startDate={startDate}
                  startDateId="s_id"
                  endDate={endDate}
                  endDateId="e_id"
                  isOutsideRange={(day) => moment().diff(day) < 0}
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(e) => setFocusedInput(e)}
                  displayFormat="YYYY-MM-DD"
                />
              </div>
            </div>
            <div style={{ marginTop: 50 }}>
              <div className="table-responsive">
                <LoadingOverlay
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                      ...base,
                      width: "75px",
                      "& svg circle": {
                        stroke: "rgba(0, 0, 0, 0.8)",
                      },
                    }),
                  }}
                  spinner
                  text="Loading ..."
                >
                  <table className="table table-bordered  ">
                    <thead>
                      <tr>
                        <th>Order Id</th>
                        <th>Customer</th>
                        <th>Total</th>
                        <th>Order Date</th>
                        <th>Payment Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {orders.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{value.order_no}</td>
                            <td>{value.customer}</td>
                            <td>{value.total}</td>
                            <td>{value.date_ordered}</td>
                            <td>
                              {value.payment_status == 0 ? (
                                <h6 style={{ color: "red" }}>UnPaid</h6>
                              ) : (
                                <h6 style={{ color: "green" }}>Paid</h6>
                              )}
                            </td>

                            <td>
                              {value.verify == 0 ? (
                                <input
                                  type="button"
                                  className="btn btn-sm btn-info"
                                  value="Verify"
                                  onClick={
                                    () => sentOtp(value.id)
                                    //updateOrderStatus(value.id, "cancelled")
                                  }
                                ></input>
                              ) : (
                                <span>
                                  <i
                                    className="fa fa-check"
                                    style={{ color: "#0f0" }}
                                  ></i>{" "}
                                  Verified
                                </span>
                              )}

                              {value.verify == 1 ? (
                                <>
                                  <br />
                                  <input
                                    type="button"
                                    className="btn btn-sm btn-primary"
                                    value="Delivery Status"
                                    //onClick={() => alert("Delivered")}
                                    onClick={() =>
                                      orderDelivery(
                                        value.id,
                                        value.payment_status
                                      )
                                    }
                                  ></input>
                                </>
                              ) : (
                                ""
                              )}

                              {value.reached_store == 0 ? (
                                <>
                                  <br />
                                  <button
                                    className="btn btn-sm btn-warning"
                                    onClick={() => itemReached(value.id)}
                                  >
                                    Item Reached
                                  </button>
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </LoadingOverlay>

                <div>
                  {/* <select onChange={(e) => setPer_page(e.target.value)}>
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    pageCount={total / per_page}
                    onPageChange={(page) => setPage(page.selected + 1)}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                  /> */}
                  <Box p={2} display="flex" justifyContent="center">
                    <Stack spacing={2}>
                      <Pagination
                        color="secondary"
                        count={total}
                        page={page}
                        onChange={(event, value) => {
                          setPage(value);
                        }}
                      />
                    </Stack>
                    <Stack spacing={2}>
                      <Select
                        value={per_page}
                        onChange={(e) => setPer_page(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingOrders;
