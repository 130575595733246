import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./Orders.css";
import PendingOrders from "./PendingOrders";
import DeliveredOrders from "./DeliveredOrders";

function Products() {
  const [deliveredcount, setDeliveredcount] = useState(0);
  const [pendingcount, setPendingcount] = useState(0);

  return (
    <Tabs>
      <TabList>
        <Tab>
          Pending{" "}
          <span class="badge badge-warning badge-counter">{pendingcount}</span>
        </Tab>
        <Tab>
          Delivered{" "}
          <span class="badge badge-success badge-counter">
            {deliveredcount}
          </span>
        </Tab>
      </TabList>

      <TabPanel>
        <PendingOrders setPendingcount={setPendingcount}></PendingOrders>
      </TabPanel>
      <TabPanel>
        <DeliveredOrders
          setDeliveredcount={setDeliveredcount}
        ></DeliveredOrders>
      </TabPanel>
    </Tabs>
  );
}

export default Products;
