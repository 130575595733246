import React, { useContext, useEffect } from "react";
import Header from "./Components/Templates/Header";
import Orders from "./Components/Orders/Orders";
import Accounts from "./Components/Accounts/Accounts";
import { Route, Switch, Redirect } from "react-router-dom";
import SideBar from "./Components/Templates/SideBar";
import Footer from "./Components/Templates/Footer";
import Dashboard from "./Components/Dashboard/Dashboard";
import Login from "./Components/Login/Login";
import Profile from "./Components/Login/Profile";
import ForgotPassword from "./Components/Login/ForgotPassword";
import ResetPassword from "./Components/Login/ResetPassword";

function App() {
  
  return (
    <div id="wrapper">
      <Switch>
        <LoginSideBar />
      </Switch>

      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <Switch>
            <LoginHeader />
          </Switch>
          <Switch>
            <ProtectedRoute path="/orders" exact component={Orders} />
            <ProtectedRoute path="/accounts" exact component={Accounts} />
            <ProtectedRoute path="/profile" exact component={Profile} />
            <Route path="/login" exact component={Login} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route
              path="/reset-password/:key1/:key2"
              exact
              component={ResetPassword}
            />
            <ProtectedRoute path="/" exact component={Dashboard} />
          </Switch>
        </div>
      </div>
      <Switch>
        <LoginFooter />
      </Switch>
    </div>
  );
}

const ProtectedRoute = ({ component: Component, path }) => {
  return (
    <Route
      path={path}
      render={(props) => {
        return localStorage.getItem("data") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              refresh: true,
              state: {
                prevLocation: path,
                error: "You need to login first!",
              },
            }}
          />
        );
      }}
    />
  );
};
const LoginHeader = () => {
  return localStorage.getItem("data") ? <Header /> : "";
};
const LoginSideBar = () => {
  return localStorage.getItem("data") ? <SideBar /> : "";
};
const LoginFooter = () => {
  return localStorage.getItem("data") ? <Footer /> : "";
};

export default App;
