import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./Accounts.css";
import PendingPayments from "./PendingPayments";
import CompletedPayments from "./CompletedPayments";
import Payouts from "./Payouts";

function Accounts() {
  const [completedcount, setCompletedcount] = useState(0);
  const [pendingcount, setPendingcount] = useState(0);
  const [payoutcount, setPayoutcount] = useState(0);

  return (
    <Tabs>
      <TabList>
        <Tab>
          Pending{" "}
          <span class="badge badge-warning badge-counter">{pendingcount}</span>
        </Tab>
        <Tab>
          Payouts{" "}
          <span class="badge badge-info badge-counter">{payoutcount}</span>
        </Tab>
        <Tab>
          Completed{" "}
          <span class="badge badge-success badge-counter">
            {completedcount}
          </span>
        </Tab>
      </TabList>

      <TabPanel>
        <PendingPayments setPendingcount={setPendingcount}></PendingPayments>
      </TabPanel>
      <TabPanel>
        <Payouts setPayoutcount={setPayoutcount}></Payouts>
      </TabPanel>
      <TabPanel>
        <CompletedPayments
          setCompletedcount={setCompletedcount}
        ></CompletedPayments>
      </TabPanel>
    </Tabs>
  );
}

export default Accounts;
